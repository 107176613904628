<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import { ScrollAreaScrollbar, type ScrollAreaScrollbarProps, ScrollAreaThumb } from 'radix-vue'
import { cn } from '@/lib/utils'

const props = withDefaults(defineProps<ScrollAreaScrollbarProps & { class?: HTMLAttributes['class'] }>(), {
	orientation: 'vertical',
})

const delegatedProps = computed(() => {
	const { class: _, ...delegated } = props

	return delegated
})
</script>

<template>
	<scroll-area-scrollbar
		v-bind="delegatedProps"
		:class="
			cn('flex touch-none select-none transition-colors',
				orientation === 'vertical'
					&& 'h-full w-2.5 border-l border-l-transparent p-px',
				orientation === 'horizontal'
					&& 'h-2.5 flex-col border-t border-t-transparent p-px',
				props.class)"
	>
		<scroll-area-thumb class="relative flex-1 rounded-full bg-border" />
	</scroll-area-scrollbar>
</template>
