<script setup lang="ts">
const $props = defineProps<{
	type: App.Cart.Enums.CartItemTypeEnum
	title: string
}>()
const { closeLocally } = useDialog()

const form = useForm<App.Cart.Data.CreateCartItemData>({
	method: 'POST',
	url: route('cart-items.store'),
	fields: {
		id: null,
		name: '',
		price: 0,
		quantity: 1,
		type: $props.type,
		variation_id: null,
		override_price: null,
	},
	transform(fields) {
		return {
			...fields,
			price: Math.round(fields.price * 100),
		}
	},
	hooks: {
		success: () => {
			closeLocally()
		},
	},
})
</script>

<template>
	<base-dialog :title="title">
		<form @submit.prevent="form.submit">
			<div class="grid gap-4">
				<div class="grid gap-1">
					<ui-label for="name">
						{{ $t('app.name') }}
					</ui-label>
					<ui-input
						id="name"
						v-model="form.fields.name"
						:placeholder="$t('app.name')"
						:disabled="form.processing"
					/>
					<transition-group appear>
						<span
							v-if="form.errors.name"
							class="text-sm text-red-600"
							v-text="form.errors.name"
						/>
					</transition-group>
				</div>
				<div class="grid gap-1">
					<ui-label for="price">
						{{ $t('app.price') }}
					</ui-label>
					<ui-input
						id="price"
						v-model="form.fields.price"
						:placeholder="$t('app.price')"
						type="number"
						:disabled="form.processing"
						step="0.01"
					/>
					<transition-group appear>
						<span
							v-if="form.errors.price"
							class="text-sm text-red-600"
							v-text="form.errors.price"
						/>
					</transition-group>
				</div>
				<ui-button :disabled="form.processing">
					<i-gg-spinner v-if="form.processing" class="mr-2 size-4 animate-spin" />
					{{ $t('app.add') }}
				</ui-button>
			</div>
		</form>
	</base-dialog>
</template>
