const isCartLoading = ref<boolean>(false)

export function useCart() {
	const isLoading = ref<boolean>(false)

	function addProductToCart(product: App.Data.ProductData, quantity: number = 1, callback?: () => void) {
		isCartLoading.value = true
		isLoading.value = true

		router
			.post(
				route('cart-items.store'),
				{
					preserveState: true,
					preserveScroll: true,
					data: <App.Cart.Data.CreateCartItemData>{
						id: product.id,
						name: product.title,
						price: product.price ? product.price.cents : 0,
						quantity,
						type: 'product',
						variation_id: null,
						override_price: null,
					},
				},
			)
			.then(() => {
				isCartLoading.value = false
				isLoading.value = false

				if (callback) {
					callback()
				}
			})
	}

	function updateCartItem(rowId: string, updateCartItem: App.Cart.Data.UpdateCartItemData) {
		isCartLoading.value = true

		router
			.put(
				route('cart-items.update', { rowId }),
				{
					preserveState: true,
					preserveScroll: true,
					data: updateCartItem,
				},
			)
			.then(() => {
				isCartLoading.value = false
			})
	}

	function emptyCart() {
		isCartLoading.value = true

		router
			.delete(
				route('cart.destroy'),
				{
					preserveState: true,
					preserveScroll: true,
				},
			)
			.then(() => {
				isCartLoading.value = false
			})
	}

	function removeCartItem(cartItem: App.Cart.Data.CartItemData) {
		isCartLoading.value = true

		router
			.delete(
				route('cart-items.destroy', { rowId: cartItem.row_id }),
				{
					preserveState: true,
					preserveScroll: true,
				},
			)
			.then(() => {
				isCartLoading.value = false
			})
	}

	function sortCartItems(rowIds: string[]) {
		isCartLoading.value = true

		router
			.post(
				route('cart-items.sort'),
				{
					preserveState: true,
					preserveScroll: true,
					data: {
						row_ids: rowIds,
					},
				},
			)
			.then(() => {
				isCartLoading.value = false
			})
	}

	function updatePaymentMethod(paymentMethod: string) {
		isCartLoading.value = true

		router
			.post(
				route('payment-method.store'),
				{
					preserveState: true,
					preserveScroll: true,
					data: {
						payment_method: paymentMethod,
					},
				},
			)
			.then(() => {
				isCartLoading.value = false
			})
	}

	function updateAmountPaid(amountPaid: number) {
		isCartLoading.value = true

		router
			.post(
				route('payment-method.store-amount-paid'),
				{
					preserveState: true,
					preserveScroll: true,
					data: {
						amount_paid: amountPaid,
					},
				},
			)
			.then(() => {
				isCartLoading.value = false
			})
	}

	return {
		cart: useProperty('cart'),
		isCartLoading,
		isLoading,
		addProductToCart,
		updateCartItem,
		emptyCart,
		removeCartItem,
		sortCartItems,
		updatePaymentMethod,
		updateAmountPaid,
	}
}
