import * as Sentry from '@sentry/vue'
import type { Plugin } from 'vue'

const sentryVuePlugin: Plugin = {
	install: (app) => {
		if (
			import.meta.env.PROD
				&& import.meta.env.VITE_SENTRY_DSN
				&& import.meta.env.VITE_SENTRY_DSN !== 'null'
		) {
			Sentry.init({
				app,
				dsn: import.meta.env.VITE_SENTRY_DSN,
				logErrors: true,
				trackComponents: true,
			})
		}
	},
}

export default sentryVuePlugin
