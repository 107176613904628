import { initializeHybridly } from 'virtual:hybridly/config'
import { createHead } from '@unhead/vue'
import './app.css'
import i18n from './i18n'
import sentryVuePlugin from '@/plugins/sentry-vue-plugin'

initializeHybridly({
	enhanceVue: (vue) => {
		const head = createHead()
		head.push({
			titleTemplate: (title) => title ? `${title} — POS Technim` : 'POS Technim',
		})

		vue.use(head)
			.use(sentryVuePlugin)
			.use(i18n)
	},
})
