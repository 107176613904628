<script setup lang="ts">
import { watchDebounced } from '@vueuse/core'
import axios from 'axios'

const products = ref<App.Data.ProductData[]>([])
const productsLoading = ref<boolean>(false)
const searchQuery = ref<string>('')

watchDebounced(
	searchQuery,
	() => {
		productsLoading.value = true

		axios.get(route('api.products.index'), {
			params: {
				searchQuery: searchQuery.value,
			},
		}).then((response) => {
			products.value = response.data.products ?? []
			productsLoading.value = false
		}).catch(() => {
			productsLoading.value = false
		})
	},
	{ debounce: 300, maxWait: 1000 },
)
</script>

<template>
	<div class="relative">
		<i-gg-search v-if="!productsLoading" class="absolute left-2 top-3 size-6 text-muted-foreground" />
		<i-gg-spinner v-else class="absolute left-2 top-3 size-6 animate-spin text-muted-foreground" />
		<ui-input
			v-model="searchQuery"
			:placeholder="$t('app.search_placeholder')"
			class="h-12 rounded-none bg-input pl-12"
		/>
		<i-gg-close v-if="searchQuery" class="absolute right-2 top-3 size-6 cursor-pointer text-destructive" @click="searchQuery = ''" />
	</div>
	<div class="mt-2">
		<ui-scroll-area v-if="products.length" class="flex h-screen pb-12">
			<div class="flex flex-1 flex-col gap-2 p-4 pt-0">
				<div class="grid grid-cols-5 gap-3">
					<product v-for="product in products" :key="product.id" :product="product" />
				</div>
			</div>
		</ui-scroll-area>
		<p v-else class="p-4 text-muted-foreground">
			{{ $t('app.no_results') }}
		</p>
	</div>
</template>
