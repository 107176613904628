<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
	SelectContent,
	type SelectContentEmits,
	type SelectContentProps,
	SelectPortal,
	SelectViewport,
	useForwardPropsEmits,
} from 'radix-vue'
import { SelectScrollDownButton, SelectScrollUpButton } from '.'
import { cn } from '@/lib/utils'

defineOptions({
	inheritAttrs: false,
})

const props = withDefaults(
	defineProps<SelectContentProps & { class?: HTMLAttributes['class'] }>(),
	{
		position: 'popper',
	},
)
const emits = defineEmits<SelectContentEmits>()

const delegatedProps = computed(() => {
	const { class: _, ...delegated } = props

	return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
	<select-portal>
		<select-content
			v-bind="{ ...forwarded, ...$attrs }"
			:class="cn(
				'relative z-50 max-h-96 min-w-32 overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
				position === 'popper'
					&& 'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
				props.class,
			)
			"
		>
			<select-scroll-up-button />
			<select-viewport :class="cn('p-1', position === 'popper' && 'h-[--radix-select-trigger-height] w-full min-w-[--radix-select-trigger-width]')">
				<slot />
			</select-viewport>
			<select-scroll-down-button />
		</select-content>
	</select-portal>
</template>
