<script lang="ts" setup>
const $props = defineProps<{
	cartItem: App.Cart.Data.CartItemData
}>()

const { isCartLoading, removeCartItem, updateCartItem } = useCart()
const quantity = ref($props.cartItem.quantity)

watch(() => $props.cartItem.quantity, (value) => quantity.value = value)

const updateCartItemDebounced = useDebounceFn(() => {
	updateCartItem($props.cartItem.row_id, {
		id: $props.cartItem.id,
		name: $props.cartItem.name,
		price: $props.cartItem.price.cents,
		quantity: quantity.value,
		type: $props.cartItem.type,
		variation_id: $props.cartItem.variation_id,
		override_price: $props.cartItem.override_price?.cents ?? null,
	})
}, 500, { maxWait: 1000 })

function updateQuantity(value: number) {
	quantity.value = value

	updateCartItemDebounced()
}
</script>

<template>
	<div class="border-b p-2 pr-4">
		<div class="mb-1 flex w-full items-center justify-between gap-2">
			<h3 class="text-sm font-bold">
				{{ cartItem.name }}
			</h3>

			<div class="flex justify-end gap-4">
				<button class="handle cursor-move">
					<i-gg-arrows-exchange-v class="size-5" />
				</button>

				<router-link :href="route('cart-items.edit', { rowId: cartItem.row_id })" class="hover:text-primary">
					<i-gg-edit-flip-h class="size-5" />
				</router-link>

				<button class="text-destructive hover:text-primary" @click="removeCartItem(cartItem)">
					<i-gg-trash class="size-5" />
				</button>
			</div>
		</div>
		<div class="flex w-full items-center justify-between gap-2">
			<div class="flex items-center gap-2">
				<div class="flex w-[150px] shrink-0">
					<ui-number-field :disabled="isCartLoading" :model-value="quantity" @update:model-value="updateQuantity">
						<ui-number-field-content>
							<ui-number-field-decrement />
							<ui-number-field-input />
							<ui-number-field-increment />
						</ui-number-field-content>
					</ui-number-field>
				</div>

				<div class="flex flex-col gap-1">
					<span class="text-sm font-bold leading-none text-primary">{{ cartItem.price.display }} <small>{{ $t('app.incl_tax') }}</small></span>
					<span class="text-xs leading-none text-muted-foreground">{{ cartItem.price_without_tax.display }} <small>{{ $t('app.excl_tax') }}</small></span>
				</div>
			</div>
			<div class="flex flex-col gap-1 text-right">
				<span class="text-sm font-bold leading-none text-primary">{{ cartItem.total.display }} <small>{{ $t('app.incl_tax') }}</small></span>
				<span class="text-xs leading-none text-muted-foreground">{{ cartItem.sub_total.display }} <small>{{ $t('app.excl_tax') }}</small></span>
			</div>
		</div>
	</div>
</template>
