<template layout>
	<div class="grid grid-cols-3">
		<div class="col-span-2 h-screen border-r-2">
			<product-search />
		</div>
		<div class="col-span-1 h-full bg-white">
			<cart />
		</div>
	</div>
</template>
