<script setup lang="ts">
import printJS from 'print-js'

const $props = defineProps<{
	order: App.Data.OrderData
	filename: string
}>()

const { closeLocally } = useDialog()
const printLoading = ref<boolean>(true)

function printInvoice() {
	printJS({
		printable: route('api.invoice', { orderId: $props.order.number, filename: $props.filename }),
		type: 'pdf',
	})
}

printJS({
	printable: route('api.invoice', { orderId: $props.order.number, filename: $props.filename }),
	type: 'pdf',
	onLoadingEnd() {
		printLoading.value = false
	},
	onPrintDialogClose() {
		closeLocally()
	},
})
</script>

<template>
	<base-dialog :title="$t('app.order_completed', { order_id: order.number })">
		<ui-button class="gap-2" :disabled="printLoading" @click="printInvoice()">
			<i-gg-printer v-if="!printLoading" class="size-5" />
			<i-gg-spinner v-else class="size-5 animate-spin" />
			<span v-if="order.status !== 'pos_quote'">{{ $t('app.print_invoice') }}</span>
			<span v-if="order.status === 'pos_quote'">{{ $t('app.print_quote') }}</span>
		</ui-button>
	</base-dialog>
</template>
