<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
	ScrollAreaCorner,
	ScrollAreaRoot,
	type ScrollAreaRootProps,
	ScrollAreaViewport,
} from 'radix-vue'
import ScrollBar from './bar.vue'
import { cn } from '@/lib/utils'

const props = defineProps<ScrollAreaRootProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
	const { class: _, ...delegated } = props

	return delegated
})
</script>

<template>
	<scroll-area-root v-bind="delegatedProps" :class="cn('relative overflow-hidden', props.class)">
		<scroll-area-viewport class="size-full rounded-[inherit]">
			<slot />
		</scroll-area-viewport>
		<scroll-bar />
		<scroll-area-corner />
	</scroll-area-root>
</template>
