<script setup lang="ts">
const $props = defineProps<{
	note: string | null
}>()

const { closeLocally } = useDialog()

const form = useForm<App.Data.UpdateNoteData>({
	method: 'POST',
	url: route('note.update'),
	fields: {
		note: $props.note ?? '',
	},
	hooks: {
		success: () => {
			closeLocally()
		},
	},
})
</script>

<template>
	<base-dialog :title="$t('app.note')">
		<form @submit.prevent="form.submit">
			<div class="grid gap-4">
				<div class="grid gap-1">
					<ui-textarea
						v-model="form.fields.note as string"
						:placeholder="$t('app.note')"
						:disabled="form.processing"
					/>
					<transition-group appear>
						<span
							v-if="form.errors.note"
							class="text-sm text-red-600"
							v-text="form.errors.note"
						/>
					</transition-group>
				</div>
				<ui-button :disabled="form.processing">
					<i-gg-spinner v-if="form.processing" class="mr-2 size-4 animate-spin" />
					{{ $t('app.add') }}
				</ui-button>
			</div>
		</form>
	</base-dialog>
</template>
