<script setup lang="ts">
import axios from 'axios'

const $props = defineProps<{
	customer: App.Data.CustomerData
}>()

const form = useForm<App.Data.UpdateCustomerData>({
	method: 'PUT',
	url: route('customers.update', { customerId: $props.customer.id }),
	fields: {
		first_name: $props.customer.first_name,
		last_name: $props.customer.last_name,
		email: $props.customer.email,

		billing_company: $props.customer.billing_address.company,
		billing_postcode: $props.customer.billing_address.postcode,
		billing_house_number: $props.customer.billing_address.house_number,
		billing_house_number_suffix: $props.customer.billing_address.house_number_suffix,
		billing_street_name: $props.customer.billing_address.street_name,
		billing_city: $props.customer.billing_address.city,
		billing_phone: $props.customer.billing_address.phone,

		shipping_use_billing: false,
		shipping_first_name: $props.customer.shipping_address.first_name,
		shipping_last_name: $props.customer.shipping_address.last_name,
		shipping_company: $props.customer.shipping_address.company,
		shipping_postcode: $props.customer.shipping_address.postcode,
		shipping_house_number: $props.customer.shipping_address.house_number,
		shipping_house_number_suffix: $props.customer.shipping_address.house_number_suffix,
		shipping_street_name: $props.customer.shipping_address.street_name,
		shipping_city: $props.customer.shipping_address.city,
	},
})

const isBillingAddressLoading = ref(false)
watchDebounced(
	() => (form.fields.billing_postcode as string) + (form.fields.billing_house_number as string) + form.fields.billing_house_number_suffix,
	() => {
		if (
			!form.fields.billing_postcode
			|| !form.fields.billing_house_number
		) {
			return
		}

		isBillingAddressLoading.value = true

		axios.post(route('api.address-autocomplete'), {
			postcode: form.fields.billing_postcode,
			house_number: form.fields.billing_house_number,
			house_number_suffix: form.fields.billing_house_number_suffix,
		}).then((response) => {
			const data = response.data.data
			if (!data) {
				return
			}

			form.fields.billing_street_name = data.street_name
			form.fields.billing_city = data.city
		}).finally(() => {
			isBillingAddressLoading.value = false
		}).catch(() => {
			isBillingAddressLoading.value = false
		})
	},
	{ immediate: true, debounce: 500, maxWait: 1000 },
)

const isShippingAddressLoading = ref(false)
watchDebounced(
	() => (form.fields.shipping_postcode as string) + (form.fields.shipping_house_number as string) + form.fields.shipping_house_number_suffix,
	() => {
		if (
			!form.fields.shipping_postcode
			|| !form.fields.shipping_house_number
		) {
			return
		}

		isShippingAddressLoading.value = true

		axios.post(route('api.address-autocomplete'), {
			postcode: form.fields.shipping_postcode,
			house_number: form.fields.shipping_house_number,
			house_number_suffix: form.fields.shipping_house_number_suffix,
		}).then((response) => {
			const data = response.data.data
			if (!data) {
				return
			}

			form.fields.shipping_street_name = data.street_name
			form.fields.shipping_city = data.city
		}).finally(() => {
			isShippingAddressLoading.value = false
		}).catch(() => {
			isShippingAddressLoading.value = false
		})
	},
	{ immediate: true, debounce: 500, maxWait: 1000 },
)
</script>

<template>
	<base-dialog :title="$t('app.edit_customer')">
		<form @submit.prevent="form.submit">
			<div class="mb-3 grid grid-cols-3 gap-2 border-b pb-3">
				<div>
					<ui-label for="first_name">
						{{ $t('app.first_name') }}
					</ui-label>
					<ui-input
						id="first_name"
						v-model="form.fields.first_name"
						:disabled="form.processing"
					/>
					<transition-group appear>
						<span
							v-if="form.errors.first_name"
							class="text-sm text-red-600"
							v-text="form.errors.first_name"
						/>
					</transition-group>
				</div>
				<div>
					<ui-label for="last_name">
						{{ $t('app.last_name') }}
					</ui-label>
					<ui-input
						id="last_name"
						v-model="form.fields.last_name"
						:disabled="form.processing"
					/>
					<transition-group appear>
						<span
							v-if="form.errors.last_name"
							class="text-sm text-red-600"
							v-text="form.errors.last_name"
						/>
					</transition-group>
				</div>
				<div>
					<ui-label for="email">
						{{ $t('app.email') }}
					</ui-label>
					<ui-input
						id="email"
						v-model="form.fields.email"
						type="email"
						:disabled="form.processing"
					/>
					<transition-group appear>
						<span
							v-if="form.errors.email"
							class="text-sm text-red-600"
							v-text="form.errors.email"
						/>
					</transition-group>
				</div>
			</div>
			<div class="grid grid-cols-2 gap-8">
				<div class="grid grid-cols-2 gap-2">
					<h2 class="col-span-2 font-bold text-primary">
						{{ $t('app.billing_address') }}
					</h2>
					<div class="col-span-2">
						<ui-label for="billing_company">
							{{ $t('app.company') }}
						</ui-label>
						<ui-input
							id="billing_company"
							v-model="form.fields.billing_company as string"
							:disabled="form.processing"
						/>
						<transition-group appear>
							<span
								v-if="form.errors.billing_company"
								class="text-sm text-red-600"
								v-text="form.errors.billing_company"
							/>
						</transition-group>
					</div>
					<div class="col-span-2">
						<ui-label for="billing_postcode">
							{{ $t('app.postcode') }}
						</ui-label>
						<ui-input
							id="billing_postcode"
							v-model="form.fields.billing_postcode as string"
							:disabled="form.processing"
						/>
						<transition-group appear>
							<span
								v-if="form.errors.billing_postcode"
								class="text-sm text-red-600"
								v-text="form.errors.billing_postcode"
							/>
						</transition-group>
					</div>
					<div class="col-span-1">
						<ui-label for="billing_house_number">
							{{ $t('app.house_number') }}
						</ui-label>
						<ui-input
							id="billing_house_number"
							v-model="form.fields.billing_house_number as string"
							:disabled="form.processing"
						/>
						<transition-group appear>
							<span
								v-if="form.errors.billing_house_number"
								class="text-sm text-red-600"
								v-text="form.errors.billing_house_number"
							/>
						</transition-group>
					</div>
					<div class="col-span-1">
						<ui-label for="billing_house_number_suffix">
							{{ $t('app.house_number_suffix') }}
						</ui-label>
						<ui-input
							id="billing_house_number_suffix"
							v-model="form.fields.billing_house_number_suffix as string"
							:disabled="form.processing"
						/>
						<transition-group appear>
							<span
								v-if="form.errors.billing_house_number_suffix"
								class="text-sm text-red-600"
								v-text="form.errors.billing_house_number_suffix"
							/>
						</transition-group>
					</div>
					<div class="col-span-1">
						<ui-label for="billing_street_name">
							{{ $t('app.street_name') }}
						</ui-label>
						<ui-input
							id="billing_street_name"
							v-model="form.fields.billing_street_name as string"
							:disabled="form.processing || isBillingAddressLoading"
						/>
						<transition-group appear>
							<span
								v-if="form.errors.billing_street_name"
								class="text-sm text-red-600"
								v-text="form.errors.billing_street_name"
							/>
						</transition-group>
					</div>
					<div class="col-span-1">
						<ui-label for="billing_city">
							{{ $t('app.city') }}
						</ui-label>
						<ui-input
							id="billing_city"
							v-model="form.fields.billing_city as string"
							:disabled="form.processing || isBillingAddressLoading"
						/>
						<transition-group appear>
							<span
								v-if="form.errors.billing_city"
								class="text-sm text-red-600"
								v-text="form.errors.billing_city"
							/>
						</transition-group>
					</div>
					<div class="col-span-2">
						<ui-label for="billing_phone">
							{{ $t('app.phone') }}
						</ui-label>
						<ui-input
							id="billing_phone"
							v-model="form.fields.billing_phone as string"
							:disabled="form.processing"
						/>
						<transition-group appear>
							<span
								v-if="form.errors.billing_phone"
								class="text-sm text-red-600"
								v-text="form.errors.billing_phone"
							/>
						</transition-group>
					</div>
				</div>
				<div class="grid grid-cols-2 gap-2">
					<div class="items-top col-span-2 flex gap-x-2">
						<ui-checkbox
							v-model:checked="form.fields.shipping_use_billing"
							class="col-span-2"
							:disabled="form.processing"
						/>
						<div class="grid gap-1.5 leading-none ">
							<label
								for="shipping_use_billing"
								class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
							>
								{{ $t('app.use_same_address') }}
							</label>
						</div>
					</div>

					<template v-if="!form.fields.shipping_use_billing">
						<h2 class="col-span-2 font-bold text-primary">
							{{ $t('app.shipping_address') }}
						</h2>
						<div>
							<ui-label for="shipping_first_name">
								{{ $t('app.first_name') }}
							</ui-label>
							<ui-input
								id="shipping_first_name"
								v-model="form.fields.shipping_first_name as string"
								:disabled="form.processing"
							/>
							<transition-group appear>
								<span
									v-if="form.errors.shipping_first_name"
									class="text-sm text-red-600"
									v-text="form.errors.shipping_first_name"
								/>
							</transition-group>
						</div>
						<div>
							<ui-label for="shipping_last_name">
								{{ $t('app.last_name') }}
							</ui-label>
							<ui-input
								id="shipping_last_name"
								v-model="form.fields.shipping_last_name as string"
								:disabled="form.processing"
							/>
							<transition-group appear>
								<span
									v-if="form.errors.shipping_last_name"
									class="text-sm text-red-600"
									v-text="form.errors.shipping_last_name"
								/>
							</transition-group>
						</div>
						<div class="col-span-2">
							<ui-label for="shipping_company">
								{{ $t('app.company') }}
							</ui-label>
							<ui-input
								id="shipping_company"
								v-model="form.fields.shipping_company as string"
								:disabled="form.processing"
							/>
							<transition-group appear>
								<span
									v-if="form.errors.shipping_company"
									class="text-sm text-red-600"
									v-text="form.errors.shipping_company"
								/>
							</transition-group>
						</div>
						<div class="col-span-2">
							<ui-label for="shipping_postcode">
								{{ $t('app.postcode') }}
							</ui-label>
							<ui-input
								id="shipping_postcode"
								v-model="form.fields.shipping_postcode as string"
								:disabled="form.processing"
							/>
							<transition-group appear>
								<span
									v-if="form.errors.shipping_postcode"
									class="text-sm text-red-600"
									v-text="form.errors.shipping_postcode"
								/>
							</transition-group>
						</div>
						<div class="col-span-1">
							<ui-label for="shipping_house_number">
								{{ $t('app.house_number') }}
							</ui-label>
							<ui-input
								id="shipping_house_number"
								v-model="form.fields.shipping_house_number as string"
								:disabled="form.processing"
							/>
							<transition-group appear>
								<span
									v-if="form.errors.shipping_house_number"
									class="text-sm text-red-600"
									v-text="form.errors.shipping_house_number"
								/>
							</transition-group>
						</div>
						<div class="col-span-1">
							<ui-label for="shipping_house_number_suffix">
								{{ $t('app.house_number_suffix') }}
							</ui-label>
							<ui-input
								id="shipping_house_number_suffix"
								v-model="form.fields.shipping_house_number_suffix as string"
								:disabled="form.processing"
							/>
							<transition-group appear>
								<span
									v-if="form.errors.shipping_house_number_suffix"
									class="text-sm text-red-600"
									v-text="form.errors.shipping_house_number_suffix"
								/>
							</transition-group>
						</div>
						<div class="col-span-1">
							<ui-label for="shipping_street_name">
								{{ $t('app.street_name') }}
							</ui-label>
							<ui-input
								id="shipping_street_name"
								v-model="form.fields.shipping_street_name as string"
								:disabled="form.processing || isShippingAddressLoading"
							/>
							<transition-group appear>
								<span
									v-if="form.errors.shipping_street_name"
									class="text-sm text-red-600"
									v-text="form.errors.shipping_street_name"
								/>
							</transition-group>
						</div>
						<div class="col-span-1">
							<ui-label for="shipping_city">
								{{ $t('app.city') }}
							</ui-label>
							<ui-input
								id="shipping_city"
								v-model="form.fields.shipping_city as string"
								:disabled="form.processing || isShippingAddressLoading"
							/>
							<transition-group appear>
								<span
									v-if="form.errors.shipping_city"
									class="text-sm text-red-600"
									v-text="form.errors.shipping_city"
								/>
							</transition-group>
						</div>
					</template>
				</div>
			</div>

			<div class="mt-4">
				<ui-button :disabled="form.processing">
					<i-gg-spinner v-if="form.processing" class="mr-2 size-4 animate-spin" />
					{{ $t('app.edit_customer') }}
				</ui-button>
			</div>
		</form>
	</base-dialog>
</template>
