<script setup lang="ts">
import { watchDebounced } from '@vueuse/core'
import axios from 'axios'

const { closeLocally } = useDialog()

const customers = ref<App.Data.CustomerData[]>([])
const customersLoading = ref<boolean>(false)
const searchQuery = ref<string>('')

watchDebounced(
	searchQuery,
	() => {
		customersLoading.value = true

		axios.get(route('api.customers.index'), {
			params: {
				searchQuery: searchQuery.value,
			},
		}).then((response) => {
			customers.value = response.data.customers ?? []
			customersLoading.value = false
		}).catch(() => {
			customersLoading.value = false
		})
	},
	{ debounce: 300, maxWait: 1000 },
)

function selectCustomer(customer: App.Data.CustomerData) {
	router.get(route('customers.attach', { customerId: customer.id }), {
		preserveState: true,
		preserveScroll: true,
		hooks: {
			after() {
				closeLocally()
			},
		},
	})
}
</script>

<template>
	<base-dialog :title="$t('app.connect_customer')">
		<div class="relative mb-2">
			<i-gg-search v-if="!customersLoading" class="absolute left-2 top-3 size-6 text-muted-foreground" />
			<i-gg-spinner v-else class="absolute left-2 top-3 size-6 animate-spin text-muted-foreground" />
			<ui-input
				v-model="searchQuery"
				:placeholder="$t('app.search_placeholder_customer')"
				class="h-12 rounded-none bg-input pl-12"
			/>
			<i-gg-close v-if="searchQuery" class="absolute right-2 top-3 size-6 cursor-pointer text-destructive" @click="searchQuery = ''" />
		</div>

		<div v-if="customers.length" class="grid grid-cols-2 gap-2">
			<div
				v-for="customer in customers"
				:key="customer.id"
				class="flex cursor-pointer flex-col rounded border p-2 hover:bg-muted"
				@click="selectCustomer(customer)"
			>
				<strong>{{ customer.first_name }} {{ customer.last_name }}</strong>
				<span class="text-xs">{{ customer.email }}</span>
			</div>
		</div>
		<p v-else class="text-muted-foreground">
			{{ $t('app.no_results') }}
		</p>
	</base-dialog>
</template>
