<script setup lang="ts">
const $props = defineProps<{
	variations: App.Data.VariationData[]
	quantity: string
	productId: string
	productName: string
}>()

const selectedVariation = ref<string>($props.variations[0].id ?? '')
const { closeLocally } = useDialog()

function addVariationToCart() {
	const variation = $props.variations.find((variation) => variation.id === selectedVariation.value)
	if (!variation) {
		return
	}

	router
		.post(
			route('cart-items.store'),
			{
				preserveState: true,
				preserveScroll: true,
				data: {
					id: $props.productId,
					name: `${$props.productName} - ${variation?.name}`,
					price: variation?.price?.cents ?? 0,
					quantity: Number($props.quantity),
					type: 'product',
					variation_id: selectedVariation.value,
					override_price: null,
				},
			},
		)
		.then(() => {
			closeLocally()
		})
}
</script>

<template>
	<base-dialog :title="$t('app.configure')">
		<ui-select v-model="selectedVariation">
			<ui-select-trigger class="w-full bg-white">
				<ui-select-value :placeholder="$t('app.select_payment_method')" />
			</ui-select-trigger>
			<ui-select-content>
				<ui-select-item v-for="variation in variations" :key="variation.id" :value="variation.id">
					{{ variation.name }} - {{ variation.price?.display }}
				</ui-select-item>
			</ui-select-content>
		</ui-select>

		<ui-button class="mt-4 w-full" :disabled="!selectedVariation" @click="addVariationToCart">
			{{ $t('app.add') }}
		</ui-button>
	</base-dialog>
</template>
