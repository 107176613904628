<script setup lang="ts">
const $props = defineProps<{
	cartItem: App.Cart.Data.CartItemData
	title: string
}>()

const { closeLocally } = useDialog()
const overridePrice = ref<string>($props.cartItem.override_price?.float ?? '')

const form = useForm<App.Cart.Data.UpdateCartItemData>({
	method: 'PUT',
	url: route('cart-items.update', { rowId: $props.cartItem.row_id }),
	fields: {
		id: $props.cartItem.id,
		name: $props.cartItem.name,
		price: $props.cartItem.price.value,
		quantity: $props.cartItem.quantity,
		type: $props.cartItem.type,
		variation_id: $props.cartItem.variation_id,
		override_price: null,
	},
	transform(fields) {
		return {
			...fields,
			override_price: overridePrice.value !== ''
				? Math.round(Number.parseFloat(overridePrice.value) * 100)
				: null,
		}
	},
	hooks: {
		success: () => {
			closeLocally()
		},
	},
})
</script>

<template>
	<base-dialog :title="title">
		<form @submit.prevent="form.submit">
			<div class="grid gap-4">
				<div class="grid gap-1">
					<ui-label for="name">
						{{ $t('app.name') }}
					</ui-label>
					<ui-input
						id="name"
						v-model="form.fields.name"
						:placeholder="$t('app.name')"
						:disabled="form.processing"
					/>
					<transition-group appear>
						<span
							v-if="form.errors.name"
							class="text-sm text-red-600"
							v-text="form.errors.name"
						/>
					</transition-group>
				</div>
				<div class="grid gap-1">
					<ui-label for="override_price">
						{{ $t('app.override_price') }}
					</ui-label>
					<ui-input
						id="price"
						v-model="overridePrice"
						:placeholder="$t('app.override_price')"
						type="number"
						:disabled="form.processing"
						step="0.01"
					/>
					<transition-group appear>
						<span
							v-if="form.errors.override_price"
							class="text-sm text-red-600"
							v-text="form.errors.override_price"
						/>
					</transition-group>
				</div>
				<ui-button :disabled="form.processing">
					<i-gg-spinner v-if="form.processing" class="mr-2 size-4 animate-spin" />
					{{ $t('app.edit') }}
				</ui-button>
			</div>
		</form>
	</base-dialog>
</template>
