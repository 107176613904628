<script setup lang="ts">
import { moveArrayElement, useSortable } from '@vueuse/integrations/useSortable'
import type { MaybeRefOrGetter } from '@vueuse/core'
import Button from './ui/button/index.vue'

const paymentMethods = useProperty('payment_methods')

const { cart, isCartLoading, emptyCart, sortCartItems, updatePaymentMethod, updateAmountPaid } = useCart()

const cartItemsElement = ref<HTMLElement | null>(null)
// @ts-expect-error weird typing issue
useSortable(cartItemsElement, cart.value?.cart_items, {
	handle: '.handle',
	onUpdate: (event: any) => {
		moveArrayElement(cart.value?.cart_items as MaybeRefOrGetter<any>, event.oldIndex, event.newIndex)

		nextTick(() => {
			const cartItems = cart?.value?.cart_items as App.Cart.Data.CartItemData[]
			const rowIds = cartItems.map((cartItem: App.Cart.Data.CartItemData) => cartItem.row_id)
			sortCartItems(rowIds)
		})
	},
})

const paymentMethod = ref<string>(cart.value?.payment_method?.id ?? '')
watch(cart, (value) => paymentMethod.value = value?.payment_method?.id ?? '', { deep: true })
watch(paymentMethod, (value) => updatePaymentMethod(value))

const amountPaid = ref<string>(cart.value?.amount_paid?.float ?? '0')
watch(cart, (value) => amountPaid.value = value?.amount_paid?.float ?? '0', { deep: true })
// watchDebounced(
// 	amountPaid,
// 	() => updateAmountPaid(amountPaid.value ? Number.parseFloat(amountPaid.value) * 100 : 0),
// 	{ debounce: 1000, maxWait: 2000 },
// )
function amountPaidChanged() {
	updateAmountPaid(amountPaid.value ? Math.round(Number.parseFloat(amountPaid.value) * 100) : 0)
}

const completingOrder = ref<boolean>(false)
function completeOrder(asQuote: boolean) {
	if (cart.value?.valid || (asQuote && cart.value?.valid_for_quote)) {
		router
			.post(
				route('cart.store', { as_quote: asQuote }),
				{
					preserveState: true,
					preserveScroll: true,
					hooks: {
						before() {
							completingOrder.value = true
						},
						after() {
							completingOrder.value = false
						},
					},
				},
			)
	}
}
</script>

<template>
	<div class="flex h-screen flex-col">
		<div class="flex h-12 shrink-0 items-center justify-between bg-primary px-2">
			<h1 class="font-bold text-white">
				{{ $t('app.cart') }} ({{ cart?.unique_item_count }})
			</h1>

			<div class="flex gap-2">
				<router-link variant="outline" :as="Button" :href="route('cart-items.create')">
					<i-gg-math-percent class="size-5" />
					<span class="ml-2">{{ $t('app.add_miscellaneous') }}</span>
				</router-link>

				<router-link variant="outline" :as="Button" :href="route('cart-items.create', { type: 'miscellaneous_product' })">
					<i-gg-toolbox class="size-5" />
					<span class="ml-2">{{ $t('app.add_miscellaneous_product') }}</span>
				</router-link>

				<ui-button variant="destructive" @click="emptyCart">
					<i-gg-trash class="size-5" />
				</ui-button>
			</div>
		</div>

		<ui-scroll-area v-if="cart?.cart_items" class="flex h-screen">
			<div ref="cartItemsElement">
				<cart-item v-for="cartItem in cart?.cart_items" :key="cartItem.row_id" :cart-item="cartItem" />
			</div>
		</ui-scroll-area>

		<div class="mt-auto flex shrink-0 flex-col bg-muted text-sm">
			<div class="flex w-full justify-between divide-x border-b">
				<div class="w-1/3 p-2 px-1 text-center">
					<strong>{{ $t('app.sub_total') }}:</strong>
					{{ cart?.sub_total.display }}
				</div>
				<div class="w-1/3 p-2 px-1 text-center">
					<strong>{{ $t('app.tax') }}:</strong>
					{{ cart?.tax.display }}
				</div>
				<div class="w-1/3 p-2 px-1 text-center">
					<strong>{{ $t('app.total') }}:</strong>
					{{ cart?.total.display }}
				</div>
			</div>

			<div class="flex w-full justify-between divide-x border-b">
				<div class="flex w-1/2 items-center gap-2 p-2">
					<div v-if="!cart?.customer && !cart?.guest" class="flex w-full items-center">
						<router-link
							class="w-full gap-2 rounded-r-none"
							variant="outline"
							:as="Button"
							:href="route('customers.index')"
						>
							<i-gg-user class="size-5 shrink-0 text-primary" />
							{{ $t('app.customer') }}
						</router-link>
						<router-link
							class="w-6 rounded-l-none"
							variant="default"
							:as="Button"
							:href="route('customers.create')"
						>
							<i-gg-math-plus class="size-4 shrink-0 text-white" />
						</router-link>

						<router-link
							class="ml-2 w-full max-w-24 gap-2"
							variant="outline"
							:as="Button"
							:href="route('guest.edit')"
						>
							<i-gg-smile-no-mouth class="size-5 shrink-0 text-primary" />
							{{ $t('app.guest') }}
						</router-link>
					</div>

					<div v-if="cart?.customer" class="flex w-full items-center gap-2">
						<router-link :href="route('customers.edit', { customerId: cart?.customer.id })" class="flex items-center gap-2">
							<i-gg-user class="size-6 shrink-0 text-primary" />
							<span>{{ cart?.customer?.first_name }} {{ cart?.customer?.last_name }}</span>
						</router-link>

						<router-link :href="route('customers.detach')" class="ml-auto text-destructive">
							<i-gg-close class="size-5" />
						</router-link>
					</div>

					<div v-if="cart?.guest" class="flex w-full items-center gap-2">
						<router-link :href="route('guest.edit')" class="flex items-center gap-2">
							<i-gg-smile-no-mouth class="size-6 shrink-0 text-primary" />
							<span>{{ cart?.guest?.first_name }} {{ cart?.guest?.last_name }}</span>
						</router-link>

						<router-link :href="route('guest.destroy')" method="DELETE" class="ml-auto text-destructive">
							<i-gg-close class="size-5" />
						</router-link>
					</div>
				</div>
				<div class="flex w-1/2 items-center gap-2 p-2">
					<i-gg-credit-card class="size-5 text-primary" />

					<ui-select v-model="paymentMethod">
						<ui-select-trigger class="w-full bg-white">
							<ui-select-value :placeholder="$t('app.select_payment_method')" />
						</ui-select-trigger>
						<ui-select-content>
							<ui-select-item v-for="paymentMethodOption in paymentMethods" :key="paymentMethodOption.id" :value="paymentMethodOption.id">
								{{ paymentMethodOption.name }}
							</ui-select-item>
						</ui-select-content>
					</ui-select>
				</div>
			</div>

			<div v-if="cart?.payment_method?.id === 'cash' || cart?.payment_method?.id === 'split_payment'" class="flex w-full justify-between divide-x border-b">
				<div class="flex w-1/2 items-center gap-2 p-2">
					<i-gg-euro class="size-6 text-primary" />
					<div class="relative w-full">
						<ui-input
							v-model="amountPaid"
							type="number"
							step="0.01"
							class="bg-white pt-2.5"
							@focus="$event.target.select()"
							@blur="amountPaidChanged"
						/>
						<div class="absolute left-0 top-0 flex items-center bg-transparent pl-3 pt-0.5 text-xs leading-none text-gray-400">
							{{ $t('app.fill_cash_amount') }}
						</div>
					</div>
					<i-gg-sync class="size-6 cursor-pointer text-primary" />
				</div>
				<div class="flex w-1/2 items-center justify-center gap-1 p-2 px-1">
					<template v-if="cart?.amount_due">
						<template v-if="cart.amount_due.cents > 0">
							<template v-if="cart?.payment_method?.id === 'split_payment'">
								<strong>{{ $t('app.amount_due_card') }}:</strong>
							</template>
							<template v-else>
								<strong>{{ $t('app.amount_due') }}:</strong>
							</template>
							{{ cart?.amount_due.display }}
						</template>
						<template v-else>
							<template v-if="cart?.payment_method?.id === 'split_payment'">
								<strong>{{ $t('app.amount_with_card') }}:</strong>
							</template>
							<template v-else>
								<strong>{{ $t('app.amount_to_be_received') }}:</strong>
							</template>

							{{ cart?.amount_due.display.replace('-', '') }}
						</template>
					</template>
				</div>
			</div>

			<div class="border-b p-2">
				<router-link :href="route('note.edit')" class="flex items-center gap-3">
					<i-gg-comment class="size-5 shrink-0 text-primary" />

					<template v-if="cart?.note">
						<p class="truncate">
							{{ cart?.note }}
						</p>
					</template>
					<template v-else>
						<p class="font-medium">
							{{ $t('app.add_note') }}
						</p>
					</template>
				</router-link>
			</div>

			<div class="flex items-center p-2">
				<template v-if="completingOrder">
					<div class="flex w-full items-center justify-center">
						<i-gg-spinner class="size-6 animate-spin" />
					</div>
				</template>
				<template v-else>
					<ui-button
						class="w-full rounded-r-none"
						size="lg"
						:disabled="!cart?.valid || isCartLoading"
						@click="completeOrder(false)"
					>
						{{ $t('app.complete') }}
					</ui-button>
					<ui-dropdown-menu>
						<ui-dropdown-menu-trigger>
							<ui-button class="w-10 rounded-l-none border-l p-0" size="lg" :disabled="!cart?.valid_for_quote || isCartLoading">
								<i-gg-chevron-down class="size-5" />
							</ui-button>
						</ui-dropdown-menu-trigger>
						<ui-dropdown-menu-content>
							<ui-dropdown-menu-item :disabled="!cart?.valid_for_quote || isCartLoading" class="cursor-pointer" @click="completeOrder(true)">
								{{ $t('app.quote') }}
							</ui-dropdown-menu-item>
						</ui-dropdown-menu-content>
					</ui-dropdown-menu>
				</template>
			</div>
		</div>
	</div>
</template>
