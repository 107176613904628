<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
	SelectItem,
	SelectItemIndicator,
	type SelectItemProps,
	SelectItemText,
	useForwardProps,
} from 'radix-vue'
import { CheckIcon } from '@radix-icons/vue'
import { cn } from '@/lib/utils'

const props = defineProps<SelectItemProps & { class?: HTMLAttributes['class'] }>()

const delegatedProps = computed(() => {
	const { class: _, ...delegated } = props

	return delegated
})

const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
	<select-item
		v-bind="forwardedProps"
		:class="
			cn(
				'relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2 pr-8 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
				props.class,
			)
		"
	>
		<span class="absolute right-2 flex size-3.5 items-center justify-center">
			<select-item-indicator>
				<check-icon class="size-4" />
			</select-item-indicator>
		</span>

		<select-item-text>
			<slot />
		</select-item-text>
	</select-item>
</template>
