<script setup lang="ts">
defineProps<{
	title?: string
}>()

const { show, close } = useDialog()
</script>

<template>
	<ui-dialog :open="show">
		<ui-dialog-content
			class="lg:max-w-[860px]"
			@interact-outside="close"
			@escape-key-down="close"
			@pointer-down-outside="close"
		>
			<ui-dialog-header>
				<ui-dialog-title v-if="title">
					{{ title }}
				</ui-dialog-title>
			</ui-dialog-header>

			<ui-dialog-description>
				<slot :close="close" />
			</ui-dialog-description>

			<ui-dialog-close
				class="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
				@click="close"
			>
				<i-gg-close class="size-4" />
			</ui-dialog-close>
		</ui-dialog-content>
	</ui-dialog>
</template>
