<script setup lang="ts">
const form = useForm({
	method: 'POST',
	url: route('login'),
	fields: {
		email: '',
		password: '',
	},
})
</script>

<template>
	<div class="container relative grid h-screen grid-cols-1 flex-col items-center justify-center lg:max-w-none lg:grid-cols-2 lg:px-0">
		<div class="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
			<div class="absolute inset-0 bg-primary" />
			<div class="relative z-20 flex items-center gap-2 text-lg font-medium">
				<svg
					class="size-6"
					viewBox="0 0 18 16"
					xmlns="http://www.w3.org/2000/svg"
				><g
					fill="none"
					fill-rule="evenodd"
					stroke="none"
					stroke-width="1"
				><g fill="currentColor" transform="translate(-465.000000, -424.000000)"><g transform="translate(465.000000, 424.000000)"><path d="M17,0 L1,0 L1,2 L17,2 L17,0 L17,0 Z M18,10 L18,8 L17,3 L1,3 L0,8 L0,10 L1,10 L1,16 L11,16 L11,10 L15,10 L15,16 L17,16 L17,10 L18,10 L18,10 Z M9,14 L3,14 L3,10 L9,10 L9,14 L9,14 Z" /></g></g></g></svg>
				{{ $t('app.application_name') }}
			</div>
			<img
				:alt="$t('app.application_name')"
				src="/images/background.jpeg"
				class="absolute inset-0 size-full object-cover opacity-50"
			/>
		</div>
		<div class="lg:p-8">
			<div class="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
				<div class="flex flex-col space-y-2 text-center">
					<h1 class="text-2xl font-semibold tracking-tight">
						{{ $t('app.login') }}
					</h1>
				</div>
				<div class="grid gap-6">
					<form @submit.prevent="form.submit">
						<div class="grid gap-4">
							<div class="grid gap-1">
								<ui-label for="email">
									{{ $t('app.email') }}
								</ui-label>
								<ui-input
									id="email"
									v-model="form.fields.email"
									:placeholder="$t('app.email')"
									type="email"
									auto-capitalize="none"
									auto-complete="email"
									auto-correct="off"
									:disabled="form.processing"
								/>
								<transition-group appear>
									<span
										v-if="form.errors.email"
										class="text-sm text-red-600"
										v-text="form.errors.email"
									/>
								</transition-group>
							</div>
							<div class="grid gap-1">
								<ui-label for="password">
									{{ $t('app.password') }}
								</ui-label>
								<ui-input
									id="password"
									v-model="form.fields.password"
									:placeholder="$t('app.password')"
									type="password"
									:disabled="form.processing"
								/>
								<transition-group appear>
									<span
										v-if="form.errors.password"
										class="text-sm text-red-600"
										v-text="form.errors.password"
									/>
								</transition-group>
							</div>
							<ui-button :disabled="form.processing">
								<i-gg-spinner v-if="form.processing" class="mr-2 size-4 animate-spin" />
								{{ $t('app.login') }}
							</ui-button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
